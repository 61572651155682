import NetworkProcess from "@/ToolAPI/NetworkProcess";

export default {

    namespaced:true,
    actions:{
        CarouselDataGet(context){
            NetworkProcess.Request_Post("/CarouselGet",null,{},(res)=>{

                context.commit("CarouselDataUpdate",res.data.data)
            });
        },
    },
    mutations:{
        CarouselDataUpdate(state,value){
            state.CarouselData = value;
        }
    },
    state:{
        CarouselData:[],
    }
}