import { render, staticRenderFns } from "./CardStruct.vue?vue&type=template&id=74c49032&scoped=true&"
import script from "./CardStruct.vue?vue&type=script&lang=js&"
export * from "./CardStruct.vue?vue&type=script&lang=js&"
import style0 from "./CardStruct.vue?vue&type=style&index=0&id=74c49032&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c49032",
  null
  
)

export default component.exports