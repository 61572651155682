import NetworkProcess from "@/ToolAPI/NetworkProcess";
//开奖信息

export default {
    namespaced:true,
    actions:{


        WinningNum_LatestData_GetEach(context){
            NetworkProcess.Request_Post("/LotteryLatestDataGet/each",
                null,{},(response)=>{
                context.commit("LotteryDataUpdate",response.data);
            })
        },
        // WinningNum_AllData_GetPointed(context,formData){
        //     NetworkProcess.messageUpload_Post("/LotteryLatestDataGet/pointed",
        //         formData,(response)=>{
        //         console.log(response.data);
        //         context.commit("LotteryDataUpdate",response.data);
        //     })
        // },
        WinningNum_AllData_GetEach(context){
            NetworkProcess.Request_Post("/LotteryAllDataGet",null,{},(response)=>{
                console.log(response.data);
                context.commit("LotteryDataUpdate",response.data);
            })
        }
    },
    mutations:{
        LotteryDataUpdate(state,value){
            let index = 0;
            for (let item in state.LotteryData_Latest){
                // if(index > 7){
                //     break;
                // }
                if(value.data[index][0].LotteryName === "福彩3d" ||
                    value.data[index][0].LotteryName === "双色球" ||
                    value.data[index][0].LotteryName === "快乐8" ||
                    value.data[index][0].LotteryName === "七乐彩" ||
                    value.data[index][0].LotteryName === "快乐8" ){
                    value.data[index][0].code = value.data[index][0].code.substring(4);
                }else if(value.data[index][0].LotteryName === "大乐透"||
                    value.data[index][0].LotteryName === "七星彩" ){
                    value.data[index][0].code = value.data[index][0].code.substring(2);
                }

                state.LotteryData_Latest[item] = value.data[index][0];
                index++;
                // console.log(1)
            }

        }

    },
    state:{
        //最新数据---7项


        //##最新数据##
        LotteryData_Latest:{
            pls:[],//排列3
            plw:[],//排列5
            dlt:[],//大乐透
            qxc:[],//七星彩
            fcsd:[],//福彩3d
            qlc:[],//七乐彩
            ssq:[],//双色球
            klb:[],//快乐8
            hdswxw:[],//华东15选5
            dfljy:[],//东方6+1
            fj22x5:[],//福建22选5
            fj31x7:[],//福建31选7
            fj31x7fj:[],//福建31选7
            fj36x7fj:[],//福建36选7
            gd36x7:[],//广东36选7
            gdhcy:[],//广东好彩1
            gxklsc:[],//广西快乐双彩
            hb20x5:[],//河北20选5
            hbhyc2:[],//河北好运彩2
            hbhyc3:[],//河北好运彩3
            hbplw:[],//河北排列5
            hbplq:[],//河北排列7
            hlj22x5:[],//黑龙江22选5
            hljp62:[],//黑龙江P62
            hlj36x7:[],//黑龙江36选7
            hljljy:[],//黑龙江6+1
            hen22x5:[],//河南22选5
            hainsjy:[],//海南4+1
            jsqws:[],//江苏7位数
            shttcx4:[],//上海天天彩选4
            zj20x5:[],//浙江20选5
            zjtcljy:[],//浙江6+1
        },
        //##全部数据##
        LotteryData_All:{
            pls:[],//排列3
            plw:[],//排列5
            dlt:[],//大乐透
            qxc:[],//七星彩
            fcsd:[],//福彩3d
            qlc:[],//七乐彩
            ssq:[],//双色球
            klb:[],//快乐8
            hdswxw:[],//华东15选5
            dfljy:[],//东方6+1
            fj22x5:[],//福建22选5
            fj31x7:[],//福建31选7
            fj31x7fj:[],//福建31选7
            fj36x7fj:[],//福建36选7
            gd36x7:[],//广东36选7
            gdhcy:[],//广东好彩1
            gxklsc:[],//广西快乐双彩
            hb20x5:[],//河北20选5
            hbhyc2:[],//河北好运彩2
            hbhyc3:[],//河北好运彩3
            hbplw:[],//河北排列5
            hbplq:[],//河北排列7
            hlj22x5:[],//黑龙江22选5
            hljp62:[],//黑龙江P62
            hlj36x7:[],//黑龙江36选7
            hljljy:[],//黑龙江6+1
            hen22x5:[],//河南22选5
            hainsjy:[],//海南4+1
            jsqws:[],//江苏7位数
            shttcx4:[],//上海天天彩选4
            zj20x5:[],//浙江20选5
            zjtcljy:[],//浙江6+1
        }





    }
}


