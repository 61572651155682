/**
*   author:胡作非为还响桃
*   Date:2024/8/8
*   FunctionDesc:""
*/
<template>
  <div class="iframe-box">
    <div class="d-flex justify-content-between p-3">
      <h2 class="iframe-title">{{iframeTitle}}</h2>
      <p style="cursor: pointer" @click="back">返回</p>
    </div>
    <div class="iframe-container">
      <iframe :src="iframeSrc" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "LotteryGraphStruct",
  mounted(){
    this.init();
  },
  data(){
    return {
      iframeTitle:"",
      iframeSrc:"",

    }
  },
  methods:{
    init(){

      if(this.$route.params.title === undefined){
        this.iframeTitle = localStorage.getItem("iframeTitle");
      }else{
        this.iframeTitle = this.$route.params.title;
        localStorage.setItem("iframeTitle",this.$route.params.title);
      }
      if(this.$route.params.iframeSrc === undefined){
        this.iframeSrc = localStorage.getItem("iframeSrc");
      }else{
        this.iframeSrc = this.$route.params.iframeSrc;
        localStorage.setItem("iframeSrc",this.$route.params.iframeSrc);
      }




    },
    back(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.iframe-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.iframe-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;

}
.iframe-container iframe{
  width: 1024px;
  height: 100%;
}

</style>