import router from "@/router";

export default {

// Number Trend:
    comprehensiveDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_zongHeFenBuTuAsc.jsp",
            }
        });
    },

    oddEvenDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "奇偶分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_jiOuFenBuTuAsc.jsp",
            }
        });
    },

    sizeDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "大小分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_daXiaoFenBuTuAsc.jsp",
            }
        });
    },

    primeAndCompositeDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "质和分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_zhiHeFenBuTuAsc.jsp",
            }
        });
    },

    sumDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "和值分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_heZhiFenBuTuAsc.jsp",
            }
        });
    },

// Missed Trend:
    missedDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "遗漏分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_yiLouFenBuTuAsc.jsp",
            }
        });
    },

// Statistical Analysis:
    basicNumberFrequencyChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "基本号码出现频率图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_jBHM_pLZhuZhuangTu.jsp",
            }
        });
    },

    regularDataTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "常规数据表",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_changGuiShuJuTuAsc.jsp",
            }
        });
    },

// Other Charts:
    winningNumberDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "中奖号码分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_zongHeFenBuTuAsc.jsp",
            }
        });
    },

    numberSumDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "号码和值分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=qlc/qlcJsp/qlc_heZhiFenBuTuAsc.jsp",
            }
        });
    },




}