import router from "@/router";

export default {
// Number Trend:
    comprehensiveDistributionChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_zongHeFenBuTuAsc.html",
            }
        });
    },

// Positioning Trend:
    firstPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第一位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_oneWeiZouShiTuAsc.html",
            }
        });
    },

    secondPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第二位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_twoWeiZouShiTuAsc.html",
            }
        });
    },

    thirdPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第三位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_threeWeiZouShiTuAsc.html",
            }
        });
    },

    fourthPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第四位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_fourWeiZouShiTuAsc.html",
            }
        });
    },

    fifthPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第五位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_fiveWeiZouShiTuAsc.html",
            }
        });
    },
    sixthPositionTrendChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "第六位号码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/df61/df61Inc/df61_sixWeiZouShiTuAsc.html",
            }
        });
    },


}