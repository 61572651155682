import router from "@/router";

export default {
// Number Trend:
    comprehensiveDrawTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合开奖走势",
                "iframeSrc": "https://chartqxc.zhcvideo.com/chart/web/jbzs_hq.html",
            }
        });
    },

    firstSixPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "前六定位走势",
                "iframeSrc": "https://chartqxc.zhcvideo.com/chart/web/jbzs_qq.html",
            }
        });
    },

    comprehensiveDistributionOldVersion() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合分布图(旧版)",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/qxc/qxcInc/qxc_zongHeFengBuTuAsc.html",
            }
        });
    },

}