import router from "@/router";

export default {

// Number Trend:
    numberTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_zongHeFenBuTuAsc.html",
            }
        });
    },

// Shape Trend:
    groupTwoCodeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "组选两码分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_wanNeng2MaFenBuTuAsc.html",
            }
        });
    },

    sizeShapeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "大小形态分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_daXiaoXingTaiFenBuTuAsc.html",
            }
        });
    },

    groupSpacingDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "组选间距分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_zuXuanJianJuFenBuTuAsc.html",
            }
        });
    },

    sumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "和值分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_heZhiFenBuTuAsc.html",
            }
        });
    },

// Omission Trend:
    directSelectionOmissionDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "直选遗漏分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_zhiXuanYiLouFenBuTuAsc.html",
            }
        });
    },

    groupOmissionDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "组选遗漏分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_zuXuanYiLouFenBuTuAsc.html",
            }
        });
    },

// Universal Group:
    universalFourCodeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "万能4码分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_wanNeng4MaFenBuTuAsc.html",
            }
        });
    },

    universalFiveCodeTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "万能5码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_wanNeng5MaFenBuTuAsc.html",
            }
        });
    },

    universalSixSevenCodeTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "万能6-7码码走势图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl3/pl3Inc/pl3_wanNeng67MaFenBuTuAsc.html",
            }
        });
    },


}