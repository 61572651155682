<template>
    <div id="MainRoot">
      <header>
        <div id="top">
<!--          顶部-->
          <p>您好，欢迎来到彩运汇。</p>
          <div>
            <p>
              <a class="ATagStandard" href="#">彩吧图库</a>&nbsp;&nbsp;|&nbsp;&nbsp;
              <a class="ATagStandard" href="#">彩吧资讯</a>&nbsp;&nbsp;|&nbsp;&nbsp;
              <a class="ATagStandard" href="#">彩吧足球</a>&nbsp;&nbsp;|&nbsp;&nbsp;
              <a class="ATagStandard" href="#">开奖助手</a>&nbsp;&nbsp;|&nbsp;&nbsp;
              <a class="ATagStandard" href="#">帮助</a>&nbsp;&nbsp;|&nbsp;&nbsp;
              <a class="ATagStandard" href="#">公告</a>
            </p>
          </div>
        </div>
        <div id="centerImg">
<!--          中部banner大图-->
          <img class="banner_img" :src="banner" alt="Example Image">
        </div>
        <div id="nav" >
          <div>
            <div class="LOGOArea">
              <img class="LOGO" :src="logo"/>
              <div>
                <span>彩运汇</span>
                <span>www.xxx.cn</span>
              </div>
            </div>
            <div class="navItem">首页</div>
            <div class="navItem">走势图</div>
            <div class="navItem">工具</div>
            <div class="navItem">遗漏</div>
            <div class="navItem">图表</div>
            <div class="navItem">开奖号</div>
            <div class="navItem">开机号</div>
            <div class="navItem">试机号</div>
            <div class="navItem">专题</div>
            <div class="navItem">技巧</div>
            <div class="navItem">术语</div>
          </div>
        </div>
        <div id="LotteryType">
<!--          彩票分类-->

        </div>
      </header>
      <main style="padding-left: 10rem;padding-right: 10rem">
        <div  class="mediaControl">
          <div >
            <WinningNumComponent/>
          </div>
          <div>
            <CarouselComponent/>
          </div>
          <div>
            <NewsComponent/>
          </div>
        </div>
<!--        <transition name="el-fade-in-linear" mode="out-in">-->
<!--          <router-view></router-view>-->
<!--        </transition>-->
        <div id="fundamentalData">
<!--          快乐8-->
           <div class="d-flex p-3 glassPlate">
              <div class="me-5" style="align-content: center;align-items: center">
                <img :src="kl8logo" alt=""/>
                <h5 class="ModelDesc">快乐8图表</h5>
              </div>
             <div class="d-flex flex-column">
               <div class="d-flex">
                 <p class="GraphTextTitle">号码走势:</p>
                 <p class="GraphIntroText" href="javascript:" @click="ComprehensiveGraphKL8">综合分布图</p>
               </div>
               <div class="d-flex">
                 <p class="GraphTextTitle">数据统计:</p>
                 <p class="GraphIntroText" href="javascript:" @click="GraphByColumnKL8">20期按列统计图</p>
                 <p class="GraphIntroText" href="javascript:" @click="GraphByRowKL8">20期按行统计图</p>
               </div>
               <div class="d-flex">
                 <p class="GraphTextTitle">指标分析:</p>
                 <p class="GraphIntroText" href="javascript:" @click="Entire80">全局80号码分布</p>
                 <p class="GraphIntroText" href="javascript:" @click="Prev40">前40号码分布</p>
                 <p class="GraphIntroText" href="javascript:" @click="Next40">后40号码分布</p>
               </div>
             </div>
           </div>
<!--           双色球-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="ssqlogo" alt=""/>
              <h5 class="ModelDesc">双色球图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="ComprehensiveGraphSSQ">综合分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallThreePointArea">红球三分区分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallFourPointArea">红球四分区分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallSevenPointArea">红球七分区分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallElevenPointArea">红球十一分区分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">蓝球走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallComprehensiveGraph">蓝球走势综合图</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallThreePointArea">蓝球三分区分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallFourPointArea">蓝球四八分区分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallSevenPointArea">蓝球奇偶质合分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallElevenPointArea">蓝球振幅走势图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">形态分布:</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallSizeDistribution">红球大小分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallOddEvenDistribution">红球奇偶分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallQualitySumDistribution">红球质和分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallMod3Distribution">红球除三余数图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallMod4Distribution">红球除四余数图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">定位走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="FirstPositionTrend">第一位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="SecondPositionTrend">第二位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="ThirdPositionTrend">第三位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="FourthPositionTrend">第四位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="FifthPositionTrend">第五位号码走势图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">遗漏走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="FirstPositionMissTrend">第一位号码遗漏图</p>
                <p class="GraphIntroText" href="javascript:" @click="SecondPositionMissTrend">第二位号码遗漏图</p>
                <p class="GraphIntroText" href="javascript:" @click="ThirdPositionMissTrend">第三位号码遗漏图</p>
                <p class="GraphIntroText" href="javascript:" @click="FourthPositionMissTrend">第四位号码遗漏图</p>
                <p class="GraphIntroText" href="javascript:" @click="FifthPositionMissTrend">第五位号码遗漏图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">统计分析:</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallMissStatistics">红球遗漏统计图</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallRegularDataTable">红球常规数据表</p>
                <p class="GraphIntroText" href="javascript:" @click="HeavyAdjacentLonelyAnalysis">重邻孤分析表</p>
                <p class="GraphIntroText" href="javascript:" @click="RedBallFrequencyHistogram">红球出号频率柱状图</p>
                <p class="GraphIntroText" href="javascript:" @click="BlueBallFrequencyHistogram">蓝球出号频率柱状图</p>
              </div>
            </div>
          </div>
<!--          大乐透-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="dltlogo" alt=""/>
              <h5 class="ModelDesc">大乐透图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToComprehensiveDistribution">综合分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">形态走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSizeDistribution">大小分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToOddEvenDistribution">奇偶分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToQualityAndDistribution">质和分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToFrontZoneSumDistribution">前区和值分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToBackZoneSumDistribution">后区和值分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">遗漏走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToOmissionDistribution">遗漏分布图</p>
              </div>
            </div>
          </div>
<!--      福彩3D-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="fc3dlogo" alt=""/>
              <h5 class="ModelDesc">福彩3D</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToComprehensiveDistribution">综合分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToGroupDistribution">组选分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSizeNumberDistribution">大小号码分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToOddEvenNumberDistribution">奇偶号码分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToQualityNumberDistribution">质和号码分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">形态走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateTo3D2DShapeDistribution">3D二维形态分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToGroupSeparatedMiddleDistribution">组选复隔中分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToGroup012RouteDistribution">组选012路分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSizeShapeDistribution">大小形态分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToOddEvenShapeDistribution">奇偶形态分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">定位走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateTo3DBallOrder">3D摆球顺序</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSingleDigitHundredChart">单选百位走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSingleDigitTenChart">单选十位走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSingleDigitUnitChart">单选个位走势图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">常用指标:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSumDistribution">和值分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSumTailChart">和值尾走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSpanChart">跨度走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSingleSpanChart">单一跨度走势图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">统计分析:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToDanTuoNumberTable">胆拖号码表</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSumTailNumberTable">和数尾数表</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSumValueNumberTable">和数值号码表</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToPrimeNumberRegionTable">质数分区号码表</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToSizeOddEvenTable">大小奇偶表</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">查询工具:</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToGroupNumberNextData">组选奖号下期数据</p>
                <p class="GraphIntroText" href="javascript:" @click="navigateToHistoricalSamePeriodQuery">历史同期号查询</p>
              </div>
            </div>
          </div>
          <!--         排列3-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="pl3logo" alt=""/>
              <h5 class="ModelDesc">排列3图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="numberTrend">综合分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">形态走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="groupTwoCodeDistribution">组选两码分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="sizeShapeDistribution">大小形态分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="groupSpacingDistribution">组选间距分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="sumDistribution">和值分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">遗漏走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="directSelectionOmissionDistribution">直选遗漏分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="groupOmissionDistribution">组选遗漏分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">万能组选:</p>
                <p class="GraphIntroText" href="javascript:" @click="universalFourCodeDistribution">万能4码分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="universalFiveCodeTrend">万能5码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="universalSixSevenCodeTrend">万能6-7码码走势图</p>
              </div>
            </div>
          </div>
          <!--         排列5-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="pl5logo" alt=""/>
              <h5 class="ModelDesc">排列5图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="numberTrend">综合分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">形态走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="oddEvenDistribution">奇偶分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="sumDistribution">和值分布图</p>
              </div>
            </div>
          </div>

          <!--         七星彩-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="qxclogo" alt=""/>
              <h5 class="ModelDesc">七星彩图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="comprehensiveDrawTrend">综合开奖走势</p>
                <p class="GraphIntroText" href="javascript:" @click="firstSixPositionTrend">前六定位走势</p>
                <p class="GraphIntroText" href="javascript:" @click="comprehensiveDistributionOldVersion">综合分布图(旧版)</p>
              </div>
            </div>
          </div>

          <!--         七乐彩-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="qlclogo" alt=""/>
              <h5 class="ModelDesc">七乐彩图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="comprehensiveDistributionChart">综合分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="oddEvenDistributionChart">奇偶分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="sizeDistributionChart">大小分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="primeAndCompositeDistributionChart">质和分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="sumDistributionChart">和值分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">遗漏走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="missedDistributionChart">遗漏分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">统计分析:</p>
                <p class="GraphIntroText" href="javascript:" @click="basicNumberFrequencyChart">基本号码出现频率图</p>
                <p class="GraphIntroText" href="javascript:" @click="regularDataTable">常规数据表</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">其他图表:</p>
                <p class="GraphIntroText" href="javascript:" @click="winningNumberDistributionChart">中奖号码分布图</p>
                <p class="GraphIntroText" href="javascript:" @click="numberSumDistributionChart">号码和值分布图</p>
              </div>
            </div>
          </div>

          <!--         6+1-->
          <div class="d-flex p-3 glassPlate">
            <div class="me-5" style="align-content: center;align-items: center">
              <img :src="sixAddOne" alt=""/>
              <h5 class="ModelDesc">东方6+1图表</h5>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <p class="GraphTextTitle">号码走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="comprehensiveDistributionChart">综合分布图</p>
              </div>
              <div class="d-flex">
                <p class="GraphTextTitle">定位走势:</p>
                <p class="GraphIntroText" href="javascript:" @click="firstPositionTrendChart">第一位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="secondPositionTrendChart">第二位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="thirdPositionTrendChart">第三位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="fourthPositionTrendChart">第四位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="fifthPositionTrendChart">第五位号码走势图</p>
                <p class="GraphIntroText" href="javascript:" @click="sixthPositionTrendChart">第六位号码走势图</p>
              </div>
            </div>
          </div>








        </div>
      </main>
      <footer class="footer">
        <div class="footer-content">
          <p>&copy; 2024 备案信息. All rights reserved.</p>
          <ul class="footer-nav">
            <li><a href="javascript:">Home</a></li>
            <li><a href="javascript:">About Us</a></li>
            <li><a href="javascript:">Services</a></li>
            <li><a href="javascript:">Contact</a></li>
          </ul>
        </div>
      </footer>
    </div>
</template>

<script>
import WinningNumComponent from "@/components/MainFrame/WinningNumComponent.vue";
import CarouselComponent from "@/components/MainFrame/CarouselComponent.vue";
import NewsComponent from "@/components/MainFrame/NewsComponent.vue";
// import router from "@/router";
import KL8 from "@/pages/commonRouteJs/KL8";
import SSQ from "@/pages/commonRouteJs/SSQ";
import DLT from "@/pages/commonRouteJs/DLT";
import FC3D from "@/pages/commonRouteJs/FC3D";
import PL3 from "@/pages/commonRouteJs/PL3";
import PL5 from "@/pages/commonRouteJs/PL5";
import QXC from "@/pages/commonRouteJs/QXC";
import QLC from "@/pages/commonRouteJs/QLC";
import SixAddOne from "@/pages/commonRouteJs/SixAddOne";
export default {
  name: "MainPage",
  components:{
    NewsComponent,
    WinningNumComponent,CarouselComponent
  },
  data(){
    return {
      logo:require("@/assets/images/LOGO.png"),
      banner:require('@/assets/images/nav2_02.png'),

      sixAddOne:require('@/assets/LotteryImg/6+1logo.png'),
      dltlogo:require('@/assets/LotteryImg/dltlogo.png'),
      kl8logo:require('@/assets/LotteryImg/kl8logo.png'),
      pl3logo:require('@/assets/LotteryImg/pl3logo.png'),
      pl5logo:require('@/assets/LotteryImg/pl5logo.png'),
      qxclogo:require('@/assets/LotteryImg/qxclogo.png'),
      ssqlogo:require('@/assets/LotteryImg/ssqlogo.png'),
      fc3dlogo:require('@/assets/LotteryImg/fc3dlogo.png'),
      qlclogo:require('@/assets/LotteryImg/7lclogo.png'),
    }
  },
  methods:{
    //快乐8
    ...KL8,
    //双色球
    ...SSQ,
    //大乐透
    ...DLT,
    //福彩3D
    ...FC3D,
    //排列3
    ...PL3,
    //排列5
    ...PL5,
    //七星彩
    ...QXC,
    //七乐彩
    ...QLC,
    //6+1
    ...SixAddOne


  }
}
</script>

<style scoped lang="scss">

@media screen and (min-width: 1500px){
  #MainRoot{
    width: 100%;
    height:100%;
    margin: 0;
    padding: 0;
    background-color: white;
  }
}
@media screen and (max-width: 1500px){
  #MainRoot{
    width: 1500px;
    height:100%;
    margin: 0;
    padding: 0;
    background-color: white;
  }
}
header{
  width: 100%;
  display: flex;
  flex-direction: column;
  >#top{
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-evenly;

  }

  >#nav{
    width: 100%;
    height: 40px;
    background-color: #B03A2E; /* 导航条背景色 */
    //padding: 10px 20px;

    text-align: center;
    >div{
      display: flex;
      justify-content: space-evenly;
      >div{
        line-height: 40px;
      }
    }
  }
}
main{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}
@media screen and (min-width : 1080px){
  .mediaControl{
    display: flex;
    justify-content: space-around;
    margin: 20px;
    >div{
      width: 100%;
    }
  }
}

@media screen and (max-width : 1080px){
  .mediaControl{
    display: flex;
    justify-content: space-around;
    margin: 20px;
    >div{
      width: 500px;
    }
  }
}
#fundamentalData{
  width: 100%;
  height: 100%;
  margin: 15px;
  display: flex;
  flex-direction: column;

}


.LOGOArea{
  height: 40px;
  display: flex;
  >div{
    line-height: 20px;
    display: flex;
    flex-direction: column;
  }
  >.LOGO{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}



.navItem {
  cursor: pointer;
  display: inline-block;
  //padding: 12px 24px;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
  color: #fff; /* 默认文字颜色 */
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  margin: 0 10px;
}

.navItem:hover {
  color: #000000; /* 悬停时的文字颜色 */
  //border: 1px solid #FFCCCC;
  background: linear-gradient(135deg, #B03A2E, #E06D6B);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.banner_img{
  width: 100%;
}
.NavStandard{
  width: 100%;
  height: 100%;
  color: white;
  text-decoration: none;
}
.NavStandard:hover{
  width: 100%;
  height: 100%;
  color: white;
  text-decoration: underline;
}
.ATagStandard{
  color: black;
  text-decoration: none;
}
.ATagStandard:hover{
  color: red;
  text-decoration: underline;
}
.content {
  flex: 1; /* 让内容区域占据剩余的空间 */
  padding: 20px;
  /* 其他内容区域的样式 */
}

.glassPlate{
  padding: 10px;
  margin: 10px;
  border: 1px solid rgba(221, 218, 218, 0.7);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
}


.GraphTextTitle{
  font-size: 20px;
  color: black;
  margin: 10px;
  padding: 5px;
}
.GraphIntroText{
  line-height: 35px;
  font-size: 15px;
  color: black;
  margin: 10px;
  padding: 5px;
  cursor:pointer;
  border-radius: 5rem;
}
.GraphIntroText:hover{
  transition: ease-in-out 5s;
  color: #FF6347;
  background: linear-gradient(90deg, rgba(243, 155, 155, 0.37),rgba(196,79,72, 0.37));
}


.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-nav {
  list-style-type: none;
  padding: 0;
}

.footer-nav li {
  display: inline-block;
  margin-right: 10px;
}

.footer-nav li:last-child {
  margin-right: 0;
}

.footer-nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #aaa;
}
</style>