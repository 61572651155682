<template>
  <transition name="el-fade-in-linear" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style >
  html,
  body,
  #app{
    margin: 0;
    padding: 0;
    background-color: #F6F8F9;
  }
  h1,h2,h3,h4,h5,h6,p,a{
    margin: 0;padding: 0;
  }
  /* Base scrollbar styles */
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px; /* For horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light grey background */
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: #c0c0c0; /* Light grey thumb */
    border-radius: 10px;
    border: 3px solid transparent; /* Space around thumb */
    background-clip: padding-box;
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Darker grey thumb on hover */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
</style>
