import router from "@/router";

export default {
    //快乐8
    ComprehensiveGraphKL8(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"快乐8综合图表",
                "iframeSrc":"https://chartkl8.zhcvideo.com/chart/web/hlsl.html",
            }
        })
    },
    GraphByColumnKL8(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"20期按列统计图",
                "iframeSrc":"https://chartkl8.zhcvideo.com/chart/web/zzt_2.html",
            }
        })
    },
    GraphByRowKL8(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"暂无统计图",
                "iframeSrc":"",
            }
        })
    },
    Prev40(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"前40号码分布图",
                "iframeSrc":"https://chartkl8.zhcvideo.com/chart/web/qj_q40.html",
            }
        })
    },
    Next40(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"后40号码分布图",
                "iframeSrc":"https://chartkl8.zhcvideo.com/chart/web/qj_h40.html",
            }
        })
    },
    Entire80(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"全局80号码分布图",
                "iframeSrc":"https://chartkl8.zhcvideo.com/chart/web/qj_80.html",
            }
        })
    },
}