import router from "@/router";

export default {

    ComprehensiveGraphSSQ(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"双色球综合图表",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssqZongHeFengBuTuAsc.jsp",
            }
        })
    },
    RedBallThreePointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"红球三分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssqThreeFengQuFengBuTuAsc.jsp",
            }
        })
    },
    RedBallFourPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"红球四分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssqFourFengQuFengBuTuAsc.jsp",
            }
        })
    },
    RedBallSevenPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"红球七分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_sevenFenQuFengBuTuAsc.jsp",
            }
        })
    },
    RedBallElevenPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"红球十一分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_elevenFenQuFengBuTuAsc.jsp",
            }
        })
    },
    //蓝球
    BlueBallComprehensiveGraph(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"蓝球走势综合图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lq_ZongHeZouShiTuAsc.jsp",
            }
        })
    },
    BlueBallThreePointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"蓝球三分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lq_threeFenQuFengBuTuAsc.jsp",
            }
        })
    },
    BlueBallFourPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"蓝球四八分区分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lq_4and8FenQuFengBuTuAsc.jsp",
            }
        })
    },
    BlueBallSevenPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"蓝球奇偶质合分布图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lqJOZH_FengBuTuAsc.jsp",
            }
        })
    },
    BlueBallElevenPointArea(){
        router.push({
            name:"LotteryGraph",
            params:{
                "title":"蓝球振幅走势图",
                "iframeSrc":"https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lq_ZhenFuZouShiTuAsc.jsp",
            }
        })
    },
    //形态分布
    RedBallSizeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球大小分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_DX_FenBuTuAsc.jsp",
            }
        });
    },

    RedBallOddEvenDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球奇偶分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_JO_FenBuTuAsc.jsp",
            }
        });
    },

    RedBallQualitySumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球质和分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_ZH_FenBuTuAsc.jsp",
            }
        });
    },

    RedBallMod3Distribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球除三余数图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_C3YS_FenBuTuAsc.jsp",
            }
        });
    },

    RedBallMod4Distribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球除四余数图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_C4YS_FenBuTuAsc.jsp",
            }
        });
    },
    // 定位走势:
    FirstPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第一位号码走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_OneChuHaoZouShiTuAsc.jsp",
            }
        });
    },

    SecondPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第二位号码走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_TwoChuHaoZouShiTuAsc.jsp",
            }
        });
    },

    ThirdPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第三位号码走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_ThreeChuHaoZouShiTuAsc.jsp",
            }
        });
    },

    FourthPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第四位号码走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_FourChuHaoZouShiTuAsc.jsp",
            }
        });
    },

    FifthPositionTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第五位号码走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_FiveChuHaoZouShiTuAsc.jsp",
            }
        });
    },
    // 遗漏走势:
    FirstPositionMissTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第一位号码遗漏图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_OneYiLouZouShiTuAsc.jsp",
            }
        });
    },

    SecondPositionMissTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第二位号码遗漏图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_TwoYiLouZouShiTuAsc.jsp",
            }
        });
    },

    ThirdPositionMissTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第三位号码遗漏图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_ThreeYiLouZouShiTuAsc.jsp",
            }
        });
    },

    FourthPositionMissTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第四位号码遗漏图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_FourYiLouZouShiTuAsc.jsp",
            }
        });
    },

    FifthPositionMissTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "第五位号码遗漏图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_FiveYiLouZouShiTuAsc.jsp",
            }
        });
    },
    // 统计分析:
    RedBallMissStatistics() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球遗漏统计图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_YLTJAsc.jsp",
            }
        });
    },

    RedBallRegularDataTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球常规数据表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_general_dataTuAsc.jsp",
            }
        });
    },

    HeavyAdjacentLonelyAnalysis() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "重邻孤分析表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssqHongQiuChongLingGuFengXiTuAsc.jsp",
            }
        });
    },

    RedBallFrequencyHistogram() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "红球出号频率柱状图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_hq_chuHao_pLZhuZhuangTu.jsp",
            }
        });
    },

    BlueBallFrequencyHistogram() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "蓝球出号频率柱状图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=ssqNew/ssqJsp/ssq_lq_chuHao_pLZhuZhuangTu.jsp",
            }
        });
    },

}