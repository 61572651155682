/**
*   author:胡作非为还响桃
*   Date:2024/7/14
*   FunctionDesc:""
*/
<template>
  <div id="WinningNumComponentRoot">
<!--    开奖情况-->
    <CardStruct>
      <template v-slot:title>
        <h4 style="margin: 5px">开奖情况</h4>
      </template>
      <template v-slot:content>
        <div class="LotteryData" v-for="(item,index) in LotteryData_Latest" :key="index">
          <div class="LotteryDataBar" v-show="item.LotteryName === '福彩3d'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="circle bg_orange">{{item.one}}</div>
            <div class="circle bg_orange">{{item.two}}</div>
            <div class="circle bg_orange">{{item.three}}</div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
          <div class="LotteryDataBar" v-show="item.LotteryName === '双色球'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="circle bg_red">{{item.one}}</div>
            <div class="circle bg_red">{{item.two}}</div>
            <div class="circle bg_red">{{item.three}}</div>
            <div class="circle bg_red">{{item.four}}</div>
            <div class="circle bg_red">{{item.five}}</div>
            <div class="circle bg_red">{{item.six}}</div>
            <div class="circle bg_blue">{{item.seven}}</div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
          <div class="LotteryDataBar" v-show="item.LotteryName === '大乐透'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="circle bg_red">{{item.one}}</div>
            <div class="circle bg_red">{{item.two}}</div>
            <div class="circle bg_red">{{item.three}}</div>
            <div class="circle bg_red">{{item.four}}</div>
            <div class="circle bg_red">{{item.five}}</div>
            <div class="circle bg_blue">{{item.six}}</div>
            <div class="circle bg_blue">{{item.seven}}</div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
          <div class="LotteryDataBar" v-show="item.LotteryName === '七乐彩'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="circle bg_red">{{item.one}}</div>
            <div class="circle bg_red">{{item.two}}</div>
            <div class="circle bg_red">{{item.three}}</div>
            <div class="circle bg_red">{{item.four}}</div>
            <div class="circle bg_red">{{item.five}}</div>
            <div class="circle bg_red">{{item.six}}</div>
            <div class="circle bg_blue">{{item.seven}}</div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
          <div class="LotteryDataBar" v-show="item.LotteryName === '七星彩'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="circle bg_red">{{item.one}}</div>
            <div class="circle bg_red">{{item.two}}</div>
            <div class="circle bg_red">{{item.three}}</div>
            <div class="circle bg_red">{{item.four}}</div>
            <div class="circle bg_red">{{item.five}}</div>
            <div class="circle bg_red">{{item.six}}</div>
            <div class="circle bg_blue">{{item.seven}}</div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
          <div class="LotteryDataBar" v-show="item.LotteryName === '快乐8'">
            <div class="LotteryName">{{item.LotteryName}}</div>
            <div>第{{item.code}}期</div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div class="circle bg_orange">{{item.one}}</div>
                <div class="circle bg_orange">{{item.two}}</div>
                <div class="circle bg_orange">{{item.three}}</div>
                <div class="circle bg_orange">{{item.four}}</div>
                <div class="circle bg_orange">{{item.five}}</div>
                <div class="circle bg_orange">{{item.six}}</div>
                <div class="circle bg_orange">{{item.seven}}</div>
                <div class="circle bg_orange">{{item.eight}}</div>
                <div class="circle bg_orange">{{item.nine}}</div>
                <div class="circle bg_orange">{{item.ten}}</div>

              </div>
              <div class="d-flex">
                <div class="circle bg_orange">{{item.eleven}}</div>
                <div class="circle bg_orange">{{item.twelve}}</div>
                <div class="circle bg_orange">{{item.thirteen}}</div>
                <div class="circle bg_orange">{{item.fourteen}}</div>
                <div class="circle bg_orange">{{item.fifteen}}</div>
                <div class="circle bg_orange">{{item.sixteen}}</div>
                <div class="circle bg_orange">{{item.seventeen}}</div>
                <div class="circle bg_orange">{{item.eighteen}}</div>
                <div class="circle bg_orange">{{item.nineteen}}</div>
                <div class="circle bg_orange">{{item.twenty}}</div>
              </div>
            </div>
            <div><a href="javascript:">走势图</a></div>
            <div><a href="javascript:">详情</a></div>
          </div>
        </div>
      </template>
    </CardStruct>
  </div>
</template>

<script>
import CardStruct from "@/components/MsgShow/CardStruct.vue";
import {mapActions, mapState} from "vuex";
export default {
  name: "WinningNumComponent",
  mounted(){
    this.WinningNum_LatestData_GetEach();
  },
  components:{
    CardStruct,
  },
  computed:{
    ...mapState("DrawPriceData",['LotteryData_Latest']),
  },
  methods:{


    ...mapActions("DrawPriceData",['WinningNum_LatestData_GetEach',
    'WinningNum_AllData_GetPointed','WinningNum_AllData_GetEach']),

  }
}
</script>

<style scoped lang="scss">
  #WinningNumComponentRoot{
    width: 100%;
    background-color: #394258;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin: 10px;
  }
  .LotteryData{
    width:30rem;

    display: flex;
    flex-direction: column;



  }
  .LotteryDataBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .circle{
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 50%;
    border: 0.2px solid black;
    text-align: center;
    line-height: 25px;
  }
  .bg_orange{
    background-color: orange;
  }
  .bg_blue{
    background-color: dodgerblue;
  }
  .bg_red{
    background-color: red;
  }
</style>