import NetworkProcess from "@/ToolAPI/NetworkProcess";

export default {
    namespaced:true,
    actions:{
        NewsDataGet(context){
            NetworkProcess.Request_Post("/NewsGet",null,{},(res)=>{
                if(res.data.statusCode !== 201){
                    context.commit("NewsDataUpdate",res.data.data)
                    context.commit("NewsDataCountUpdate",res.data.dataTot)
                }
            })
        }
    },
    mutations:{
        NewsDataUpdate(state,value){
            state.NewsData = value;
            // let oldDataArr = state.NewsData;
            // value.forEach((element)=>{
            //     oldDataArr.push(element)
            // });
            // state.NewsData = oldDataArr;
        },
        NewsDataCountUpdate(state,value){
            state.NewsDataCount = value;
        }
    },
    state:{

        NewsData:[],
        NewsDataCount:0,

    }


}