/**
*   author:胡作非为还响桃
*   Date:2024/8/7
*   FunctionDesc:""
*/
<template>
  <div id="NewsRoot">
    <CardStruct>
      <template v-slot:title>
        <h4 style="margin: 5px">彩市新闻</h4>
      </template>
      <template v-slot:content>
        <div class="news-container">
          <ul class="news-list">
            <li class="news-item" v-for="(item,index) in NewsData" :key="index">
              <a :href="item.news_url" target="_blank">
                <div class="news-thumbnail">
                  <img :src="'http://90kp.com'+item.news_img_url" alt="News Thumbnail">
                </div>
                <div class="news-details">
                  <h3 class="news-title">{{item.news_title}}</h3>
                  <p class="news-description">{{item.news_content}}</p>
                  <span class="news-date">{{item.update_date}}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </CardStruct>
  </div>
</template>

<script>
import CardStruct from "@/components/MsgShow/CardStruct.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: "NewsComponent",
  components: {CardStruct},
  mounted() {
    this.RequestNewsData();
  },
  computed:{

    ...mapState("NewsData",['NewsData','NewsDataCount'])
  },
  methods:{

    RequestNewsData(){
      this.NewsDataGet();
    },

    ...mapActions("NewsData",['NewsDataGet']),
  },

}
</script>

<style scoped lang="scss">
  #NewsRoot{
    width: 100%;
    height: 300px;
    background-color: #394258;
    margin: 10px 0 0 30px;
  }
  //.newsItem{
  //  color: black;
  //  text-decoration: none;
  //}
  //.newsItem:hover{
  //  color: red;
  //  text-decoration: underline;
  //}
  .news-container {
    width:100%;
    height: 100%;
    //max-width: 800px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    overflow: auto;
  }

  .news-list {
    list-style-type: none;
    padding: 0;
  }

  .news-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }

  .news-item:last-child {
    border-bottom: none;
  }

  .news-item a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }

  .news-thumbnail {
    flex: 0 0 120px; /* Fixed width for thumbnail */
    margin-right: 15px;
  }

  .news-thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .news-details {
    flex: 1;
  }

  .news-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .news-description {
    color: #666;
    margin-bottom: 10px;
  }

  .news-date {
    font-size: 14px;
    color: #888;
  }


</style>