import router from "@/router";

export default {
// Number Trend:
    numberTrend() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "综合分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl5/pl5Inc/pl5_zongHeFenBuTuAsc.html",
            }
        });
    },

// Shape Trend:
    oddEvenDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "奇偶分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl5/pl5Inc/pl5_jiOuXingTaiFenBuTuAsc.html",
            }
        });
    },

    sumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                "title": "和值分布图",
                "iframeSrc": "https://tubiao.zhcw.com/tubiao/pl5/pl5Inc/pl5_heZhiFenBuTuAsc.html",
            }
        });
    },

}