import router from "@/router";

export default {

    // 号码走势:
    navigateToComprehensiveDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "综合分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_zongHeFenBuTuAsc.html",
            }
        });
    },

    // 形态走势:
    navigateToSizeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "大小分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_daXiaoFenBuTuAsc.html",
            }
        });
    },
    navigateToOddEvenDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "奇偶分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_jiOuFenBuTuAsc.html",
            }
        });
    },

    navigateToQualityAndDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "质和分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_zhiHeFenBuTuAsc.html",
            }
        });
    },

    navigateToFrontZoneSumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "前区和值分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_qianQuHeZhiFenBuTuAsc.html",
            }
        });
    },

    navigateToBackZoneSumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "后区和值分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_houQuHeZhiFenBuTuAsc.html",
            }
        });
    },

    // 遗漏走势:
    navigateToOmissionDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "遗漏分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/dlt/dltInc/dlt_yiLouFenBuTuAsc.html",
            }
        });
    },
}