import VueRouter from "vue-router";
//要引入组件
import MainPage from "@/pages/MainPage/MainPage.vue";
import LotteryGraphStruct from "@/components/MsgShow/LotteryGraphStruct.vue";



//测试

const index = new VueRouter({
    routes:[
        {
            //规则
            name:"MainPage",
            path:"/",
            component:MainPage,
        },
        {
            name:"LotteryGraph",
            path:"/LotteryGraph",
            component:LotteryGraphStruct,
        }
    ]
});
export default index