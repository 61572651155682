/**
*   author:胡作非为还响桃
*   Date:2024/7/12
*   FunctionDesc:""
*/
<template>
  <div id="CardStructRoot" :class="CardEntireStyleClass">
    <div class="CardHeadStyle">
      <div class="CardTitle">
        <slot name="title"></slot>
      </div>
      <div class="Details">
        <slot name="details"></slot>
      </div>
    </div>
    <div class="CardBodyStyle">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardStruct",
  components: {},
  props:['borderDash'],
  // mounted:{
  //
  // },
  data(){
    return {
      CardEntireStyleClass:"",
    }
  },
  methods:{
    CardRenderer(){
      //卡片组件渲染
      if(this.borderDash){
        this.CardEntireStyleClass = "CardBoderedDashed"
      }else{
        this.CardEntireStyleClass = "CardBodered"
      }
    }
  }
}
</script>

<style scoped lang="scss">
#CardStructRoot{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.CardHeadStyle{
  width: 100%;
  height: 40px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  background-color: #E36A64;
}
.CardBodyStyle{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

}
.CardBodered{
  border: 1px solid #E0E8F6;
}
.CardBoderedDashed{
  border: 1px dashed #E0E8F6;
}


</style>