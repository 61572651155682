/**
*   author:胡作非为还响桃
*   Date:2024/7/29
*   FunctionDesc:""
*/
<template>
  <div id="CarouselComponentRoot">
    <!--轮播图-->
    <el-carousel class="CarouselContainer">
      <el-carousel-item class="CarouselItem" v-for="(item,index) in CarouselData" :key="index">
        <el-image :src="'http://90kp.com'+item.carousel_url"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "CarouselComponent",
  mounted() {
    this.CarouselDataGet();
  },
  computed:{
    ...mapState("CarouselData",['CarouselData'])
  },
  data(){
    return {

    }
  },
  methods:{


    ...mapActions("CarouselData",['CarouselDataGet'])
  },


}
</script>

<style scoped lang="scss">
  #CarouselComponentRoot{
    width: 100%;
    margin: 0 30px 0 10px;
    height: 100%;
  }
  .CarouselContainer{
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 10px;
  }
  .CarouselItem{
    width: 100%;
    height: 100%;
  }
  .CarouselItem:hover{
    transform: scale(1.5);
    transition: ease-in-out 0.5s;
  }
</style>