import router from "@/router";

export default {

    // 号码走势:
    navigateToComprehensiveDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "综合分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3dZongHeZouShiTuAsc.jsp",
            }
        });
    },

    navigateToGroupDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "组选分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_chuHaoFengXiTuAsc.jsp",
            }
        });
    },

    navigateToSizeNumberDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "大小号码分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_DX_num_FengBuTuAsc.jsp",
            }
        });
    },

    navigateToOddEvenNumberDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "奇偶号码分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_JO_num_FengBuTuAsc.jsp",
            }
        });
    },

    navigateToQualityNumberDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "质和号码分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_zhiHeHaoMaFengBuTuAsc.jsp",
            }
        });
    },

    // 形态走势:
    navigateTo3D2DShapeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "3D二维形态分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_erWeiXingTaiFenBuTuAsc.jsp",
            }
        });
    },

    navigateToGroupSeparatedMiddleDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "组选复隔中分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_ZouShi_fugezhongfenbutuAsc.jsp",
            }
        });
    },

    navigateToGroup012RouteDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "组选012路分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_ZouShi_012fenbutuAsc.jsp",
            }
        });
    },

    navigateToSizeShapeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "大小形态分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_DXXT_FengBuTuAsc.jsp",
            }
        });
    },

    navigateToOddEvenShapeDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "奇偶形态分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_JOXT_FengBuTuAsc.jsp",
            }
        });
    },

    // 定位走势:
    navigateTo3DBallOrder() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "3D摆球顺序",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_qh_bqsx.jsp",
            }
        });
    },

    navigateToSingleDigitHundredChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "单选百位走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_danXuanBaiWeiZouShiTuAsc.jsp",
            }
        });
    },

    navigateToSingleDigitTenChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "单选十位走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_danXuanShiWeiZouShiTuAsc.jsp",
            }
        });
    },

    navigateToSingleDigitUnitChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "单选个位走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_danXuanGeWeiZouShiTuAsc.jsp",
            }
        });
    },

    // 常用指标:
    navigateToSumDistribution() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "和值分布图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3dHeZhiZouShiTuAsc.jsp",
            }
        });
    },

    navigateToSumTailChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "和值尾走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3dHeZhiHeWeiZouShiTuAsc.jsp",
            }
        });
    },

    navigateToSpanChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "跨度走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3dZongKuaDuZouShiTuAsc.jsp",
            }
        });
    },

    navigateToSingleSpanChart() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "单一跨度走势图",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_DanYiKuaDu_ZouShiTuAsc.jsp",
            }
        });
    },

    // 统计分析:
    navigateToDanTuoNumberTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "胆拖号码表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_table_dthm_suChaTu.jsp",
            }
        });
    },

    navigateToSumTailNumberTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "和数尾数表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_table_hsw_fenQuTu.jsp",
            }
        });
    },

    navigateToSumValueNumberTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "和数值号码表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_table_hs_fenQuTu.jsp",
            }
        });
    },

    navigateToPrimeNumberRegionTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "质数分区号码表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_table_zs_fenQuHaoMaZuHeTu.jsp",
            }
        });
    },
    navigateToSizeOddEvenTable() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "大小奇偶表",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_table_dxjo_fenQuTu.jsp",
            }
        });
    },

    // 查询工具:
    navigateToGroupNumberNextData() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "组选奖号下期数据",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_ZuXuanXiaQiShuJuChaXun_Asc.jsp",
            }
        });
    },

    navigateToHistoricalSamePeriodQuery() {
        router.push({
            name: "LotteryGraph",
            params: {
                title: "历史同期号查询",
                iframeSrc: "https://tubiao.zhcw.com/tubiao/zhcw_agent.jsp?url=3d/3dJsp/3d_LiShiTongQiChaXunTuAsc.jsp",
            }
        });
    },
}