//引入vuex
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
import DrawPriceData from "@/store/LotteryData/DrawPriceData";
import CarouselData from "@/store/CommonData/CarouselData";
import NewsData from "@/store/CommonData/NewsData";


export default new Vuex.Store({
    modules:{
        DrawPriceData,CarouselData,NewsData
    }
    // getters

})

